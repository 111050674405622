<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card>
        <v-card-title>
          <v-layout align-center justify-space-between>
            <span class="headline">{{ formTitle }}</span>

            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <div class="acc-t">
            Have you already checked the data and want to accept user verification?
          </div>
        </v-card-text>

        <v-card-actions class="px-3 pt-0">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            No, cancel action
          </v-btn>
          <v-btn color="success" :loading="isLoading" @click.native="save">
            Yes, I'm sure
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {verificationVideoUpdate} from "../../../api/verificationVideo"

export default {
  name: "AcceptVideoModal",
  props: {
    value: Boolean,
    id: {
      type: [Number, String],
      default: null
    },
  },
  data() {
    return {
      isLoading: false,
      formTitle: "Accept Verification Video",
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  watch: {
    show: function (isOpen) {
      if (isOpen) {
        document.querySelector("html").style.overflowY = "hidden"
      } else {
        document.querySelector("html").style.overflowY = "auto"
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    async save() {
      const params = {
        video_id: this.id,
        status: 'accepted'
      }
      this.isLoading = true
      this.$emit("video-accepted")
      this.close()
      await verificationVideoUpdate(params)
          .then(response => {
            if (response && response.status === 200) {
              this.close()
              this.$emit("video-accepted")
              this.$notificationShow('Video verification is successfully accepted')
            }
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped>
.acc-t {
  font-size: 16px;
  margin-bottom: 16px;
}
</style>