<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card>
        <v-card-title>
          <v-layout align-center justify-space-between>
            <span class="headline">{{ formTitle }}</span>

            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <div>
            <div class="mb-2 grey--text">
              Add more info (not necessary)
            </div>
            <v-textarea
              v-model="description"
              :error-messages="descriptionErrors"
              :counter="maxDesc"
              solo
              box
              rows="7"
              name="description"
              value=""
              @input="descriptionChange"
            />
          </div>
        </v-card-text>

        <v-card-actions class="px-3 pt-0">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            Cancel
          </v-btn>
          <v-btn
            color="success"
            :loading="isLoading"
            @click.native="save"
          >
            Decline
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import {userBanned} from "../../../../api/user";
import {verificationVideoUpdate} from "../../../api/verificationVideo"
export default {
  name: "RejectVideoModal",
  props: {
    value: Boolean,
    id: {
      type: [Number, String],
      default: null
    },
  },
  data() {
    return {
      isLoading: false,
      formTitle: "Decline verification video ",
      description: null,
      reasonErrors: null,
      descriptionErrors: null,
      maxDesc: 1000
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  watch: {
    show: function(isOpen) {
      if (isOpen) {
        document.querySelector("html").style.overflowY = "hidden"
      } else {
        document.querySelector("html").style.overflowY = "auto"
      }
    }
  },
  methods: {
    descriptionChange() {
      this.descriptionErrors = null
      if (this.description && this.description.length && this.description.length > this.maxDesc) {
        this.$nextTick(() => {
          this.description = this.description.slice(0, this.maxDesc)
        })
      }
    },
    descriptionValid(val) {
      return val.length <= this.maxDesc
    },
    close() {
      this.description = null
      this.descriptionErrors = null
      this.show = false
    },
    async save() {
      this.descriptionErrors = null
      if (this.description === "" || this.description === null) {
        this.descriptionErrors = "The description field is required"
        return
      }
      if (this.description && this.description.length) {
        if (!this.descriptionValid(this.description.trim())) {
          this.descriptionErrors = `Max ${this.maxDesc} characters`
          return
        }
      }
      const params = {
        video_id: this.id,
        description: this.description,
        status: 'declined'
      }
      this.isLoading = true
      await verificationVideoUpdate(params)
          .then(response => {
            if (response && response.status === 200) {
              this.close()
              this.$emit("video-declined")
              this.$notificationShow('Video verification is declined')
            }
          })
          .catch(error => {
            this.descriptionErrors = error.response.data.errors.description
                ? error.response.data.errors.description[0]
                : null
          })
          .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped>

</style>