import { render, staticRenderFns } from "./RejectVideoModal.vue?vue&type=template&id=06d0f724&scoped=true"
import script from "./RejectVideoModal.vue?vue&type=script&lang=js"
export * from "./RejectVideoModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d0f724",
  null
  
)

export default component.exports